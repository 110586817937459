import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { ExemptionDetails } from './ExemptionDetails';
import { Exemption } from '@/api/types';
import { LabelDisplay } from '@/components/LabelDisplay';

interface Props {
  exemption: Exemption;
}

const getStyles = (theme: GrafanaTheme2) => {
  return {
    container: css({
      display: 'flex',
      flexFlow: 'row wrap',
      gap: theme.spacing(2),
      padding: `0 ${theme.spacing(2)}`,
    }),
  };
};

export const ExemptionSummary = ({ exemption }: Props) => {
  const styles = useStyles2(getStyles);

  const getSelectorArray = () => {
    const openBracket = exemption.stream_selector.indexOf('{') + 1;
    const closeBracket = exemption.stream_selector.indexOf('}');

    return exemption.stream_selector.substring(openBracket, closeBracket).split(',');
  };

  return (
    <div className={styles.container}>
      <LabelDisplay labels={getSelectorArray()} title="Stream selectors" />

      <ExemptionDetails exemption={exemption} />
    </div>
  );
};
