import React from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, TagList, useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    tagContainer: css({
      flex: 2,
    }),
    tagList: css({
      justifyContent: 'flex-start',
      li: {
        span: {
          backgroundColor: theme.colors.background.secondary,
          border: `1px solid ${theme.colors.border.medium}`,
          color: theme.colors.text.primary,
        },
      },
    }),
  };
};

export const LabelDisplay = ({
  description = '',
  labels,
  title,
}: {
  description?: string;
  labels: string[];
  title: string;
}) => {
  const styles = useStyles2(getStyles);

  return (
    <Field className={styles.tagContainer} label={title} description={description}>
      <TagList className={styles.tagList} tags={labels} />
    </Field>
  );
};
