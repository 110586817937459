import React, { useEffect, useMemo, useRef, useState } from 'react';

import { css } from '@emotion/css';
import { inRange as lodashInRange } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, Checkbox, ConfirmModal, Field, Input, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';
import { useRecommendations, useUpdateRecommendationsMutation } from '@/hooks/api-hooks';
import { useUserPermissions } from '@/hooks/context-hooks';
import { DROP_RATE_UPPER_LIMIT_EXCLUSIVE, UPDATED_RATE_INPUT_WIDTH } from '@/utils/constants';
import { getValueAndUnit } from '@/utils/formats';
import { noop } from '@/utils/methods';

interface Props {
  recommendation: PatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => ({
  currentRateContainer: css({
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row',
    gap: theme.spacing(2),
  }),
  modalBody: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  updatedRateField: css({
    marginTop: theme.spacing(2),
  }),
});

export const EditPattern = ({ recommendation }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [locked, setLocked] = useState(false);
  const [updatedRate, setUpdatedRate] = useState(`${recommendation.configured_drop_rate}`);
  const { isPending: recommendationUpdating, mutateAsync: updateRecommendationsAsync } =
    useUpdateRecommendationsMutation();
  const { data: recommendations } = useRecommendations();
  const styles = useStyles2(getStyles);
  const userPermissions = useUserPermissions();

  const ref = useRef<HTMLInputElement>(null);
  useEffect(() => {
    // When switching isOpen to true, focus on input
    isOpen && ref.current?.focus();
  }, [isOpen]);

  const projectedSavingsWithRecommended = recommendation.volume * (recommendation.recommended_drop_rate / 100);

  const projectedSavingsWithCustomValue = useMemo(
    () => recommendation.volume * (Number(updatedRate) / 100),
    [recommendation.volume, updatedRate]
  );

  const onConfirm = async () => {
    const mappedRecommendations = new Map(recommendations.mappedItems);

    mappedRecommendations.set(recommendation.pattern, {
      ...recommendation,
      configured_drop_rate: Number(updatedRate),
      locked,
    });

    await updateRecommendationsAsync(Array.from(mappedRecommendations.values()));
    setIsOpen(false);
  };

  return (
    <>
      <Button
        tooltip={
          !userPermissions.canApplyPatterns
            ? 'You do not have permission for this action.'
            : recommendation && recommendation.locked
            ? 'This pattern is locked'
            : 'Edit this pattern.'
        }
        icon="pen"
        aria-label="individual-pattern-edit"
        variant="secondary"
        size="sm"
        disabled={!userPermissions.canApplyPatterns}
        onClick={() => {
          setLocked(recommendation.locked);
          setIsOpen(true);
        }}
      />
      <ConfirmModal
        title="Edit pattern"
        isOpen={isOpen}
        body={
          <div className={styles.modalBody}>
            <div>
              Set up custom drop rate for the selected pattern. The recommended drop rate is{' '}
              {recommendation.recommended_drop_rate} with {getValueAndUnit(projectedSavingsWithRecommended, 2)} of
              projected savings.
            </div>
            <div className={styles.currentRateContainer}>
              <Field
                label="Custom drop rate"
                disabled={locked}
                invalid={!lodashInRange(Number(updatedRate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE)}
                error="Drop percentage must be between 0% and 100% inclusive"
              >
                <Input
                  ref={ref}
                  aria-label="Custom drop rate"
                  width={UPDATED_RATE_INPUT_WIDTH}
                  type="number"
                  value={updatedRate}
                  suffix="%"
                  onFocus={(event) => event.target.select()}
                  onChange={(v) => {
                    setUpdatedRate(v.currentTarget.value);
                  }}
                />
              </Field>
              <Checkbox
                value={locked}
                onClick={(event) => setLocked(event.currentTarget.checked)}
                label="Lock the current pattern at the custom drop rate"
              />
            </div>
            <div>
              Projected savings with the custom drop rate: {getValueAndUnit(projectedSavingsWithCustomValue, 2)}
            </div>
          </div>
        }
        confirmText={recommendationUpdating ? 'Applying...' : 'Apply'}
        disabled={!updatedRate || !lodashInRange(Number(updatedRate), DROP_RATE_UPPER_LIMIT_EXCLUSIVE)}
        confirmButtonVariant={recommendationUpdating ? 'secondary' : 'primary'}
        onConfirm={recommendationUpdating ? noop : onConfirm}
        onDismiss={
          recommendationUpdating
            ? noop
            : () => {
                setUpdatedRate(`${recommendation.configured_drop_rate}`);
                setIsOpen(false);
              }
        }
      />
    </>
  );
};
