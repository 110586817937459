import { tz } from 'moment-timezone';

import { dateTimeFormat, getTimeZone } from '@grafana/data';

export const formatDateTime = (dateTime: string) => {
  let timeZone = getTimeZone();
  if (timeZone === 'browser') {
    timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  }
  const date = new Date(dateTime);

  return `${dateTimeFormat(date, {
    format: 'ddd, DD MMM YYYY HH:mm:ss',
    timeZone,
  })} ${tz(timeZone).format('z')}`;
};
