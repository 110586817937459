import { camelCase as lodashCamelCase, kebabCase as lodashKebabCase } from 'lodash';

import { PageDefinition, PageType } from '@/types';

export const pageDefinitionToRoute = (page: PageDefinition) => lodashKebabCase(page.id);

const INVALID_LOG_LINE_PLACEHOLDER = '<_><_>';

export const buildLogQueryFromPattern = (pattern: string, serviceNames: string[]) => {
  let result = '';

  if (!pattern || !serviceNames.length) {
    return '';
  }

  const patternQL = pattern.replaceAll(/<[A-Z*.]+>/g, '<_>');

  const serviceNameQL = serviceNames
    .map((name) => name.substring(name.indexOf('=') + 2, name.indexOf('}') - 1))
    .join('|');

  result = '{service_name=~"' + `${serviceNameQL}` + '"} |> `' + patternQL + '`';

  if (result.includes(INVALID_LOG_LINE_PLACEHOLDER)) {
    result = result.replace(INVALID_LOG_LINE_PLACEHOLDER, '<_>');
  }

  return result;
};

export const noop = () => {
  return;
};

export const pageTypeFromRoute = (pageRoute: string): PageType => lodashCamelCase(pageRoute) as PageType;
