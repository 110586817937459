import React, { Suspense, useMemo } from 'react';

import { css } from '@emotion/css';
import Skeleton from 'react-loading-skeleton';

import { GrafanaTheme2 } from '@grafana/data';
import { Column, InteractiveTable, useStyles2, useTheme2 } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';
import { EditPattern } from '@/components/PatternActions/EditPattern';
import { PatternDisplay } from '@/components/PatternSummary/PatternDisplay';
import { useRecommendations } from '@/hooks/api-hooks';

const getStyles = (theme: GrafanaTheme2) => ({
  currentRate: css({
    textAlign: 'right',
  }),
  editButton: css({
    textAlign: 'center',
  }),
  noRows: css({
    color: theme.colors.text.secondary,
    fontStyle: 'italic',
    margin: `${theme.spacing(2)} auto 0`,
  }),
  patternCell: css({
    '::after': {
      color: theme.colors.text.primary,
      content: 'attr(data-pattern)',
      left: 0,
      overflow: 'hidden',
      pointerEvents: 'none',
      position: 'absolute',
      right: 0,
      textOverflow: 'ellipsis',
      top: 0,
      whiteSpace: 'nowrap',
    },
    color: 'transparent',
    maxHeight: '1.5em',
    overflow: 'hidden',
    position: 'relative',
    wordBreak: 'break-word',
  }),
});

type Styles = ReturnType<typeof getStyles>;

const getColumns = (styles: Styles, canUserEditExemption: boolean): Array<Column<PatternRecommendation>> => {
  return [
    {
      cell: ({ row: { original } }) => (
        <div className={styles.patternCell} data-pattern={original.pattern}>
          {original.pattern}
        </div>
      ),
      header: 'Pattern',
      id: 'pattern',
    },
    {
      cell: ({ row: { original } }) => <div className={styles.currentRate}>{original.configured_drop_rate}%</div>,
      header: 'Current',
      id: 'configured_drop_rate',
    },
    {
      cell: ({ row: { original } }) => (
        <div className={styles.editButton}>
          <EditPattern recommendation={original} />
        </div>
      ),
      disableGrow: true,
      header: 'Actions',
      id: 'exploreLink',
    },
  ];
};

const Component = () => {
  const styles = useStyles2(getStyles);
  const { data: recommendations } = useRecommendations();

  const columns = useMemo(() => getColumns(styles, true), [styles]);

  const tableData = useMemo(() => {
    if (!recommendations || recommendations.items.length === 0) {
      return [];
    }

    return recommendations.items.filter((recommendation) => recommendation.locked);
  }, [recommendations]);

  const renderSubComponent = (recommendation: PatternRecommendation) => {
    return <PatternDisplay pattern={recommendation.pattern} />;
  };

  return (
    <>
      <InteractiveTable
        pageSize={5}
        renderExpandedRow={renderSubComponent}
        columns={columns}
        data={tableData}
        getRowId={(recommendation: PatternRecommendation) => recommendation.pattern}
      />
      {!tableData.length && (
        <div className={styles.noRows} data-testid="no-data-message">
          No exemptions available
        </div>
      )}
    </>
  );
};

export const LockTable = () => {
  const theme = useTheme2();
  return (
    <Suspense fallback={<Skeleton count={5} height={theme.spacing(4)} />}>
      <Component />
    </Suspense>
  );
};
