import React from 'react';

import { css } from '@emotion/css';
import { keys as lodashKeys, round as lodashRound } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Divider, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';
import { Bytes } from '@/components/Bytes';

interface Props {
  recommendation: PatternRecommendation;
}

const getStyles = (theme: GrafanaTheme2) => ({
  columnValues: css({
    color: theme.colors.text.secondary,
  }),
  defaultValue: css({
    color: theme.colors.emphasize(theme.colors.text.secondary, 0.5),
    fontStyle: 'italic',
    userSelect: 'none',
  }),
  detailColumn: css({
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
  }),
  expandSession: css({
    display: 'flex',
    justifyContent: 'space-between',
    margin: `0 ${theme.spacing(15)}`,
    maxWidth: '80%',
  }),
  infoIcon: css({
    marginLeft: theme.spacing(1),
  }),
  serviceNameWrapper: css({
    marginLeft: theme.spacing(0),
  }),
});

export const ServiceNameBreakDown = ({ recommendation }: Props) => {
  const { attribution, configured_drop_rate } = recommendation;
  const segments = recommendation.segments || {};
  const styles = useStyles2(getStyles);
  const serviceNames = lodashKeys(attribution);

  const hasDropRateOverrides =
    Object.values(recommendation.segments || {}).find((segment) => segment.drop_rate != null) !== undefined;

  return (
    <div className={styles.serviceNameWrapper} data-testid="recommendation-break-down">
      <h3>Pattern breakdown by service</h3>
      <Divider />
      <div className={styles.expandSession}>
        <div className={styles.detailColumn}>
          <div>
            Service name
            <Tooltip placement={'top'} content={'Pattern generated from these identified services.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>
          {serviceNames.map((service) => {
            const name = service.substring(service.indexOf('"') + 1, service.lastIndexOf('"'));
            return (
              <div key={name} className={styles.columnValues}>
                {name}
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Percentage
            <Tooltip placement={'top'} content={'Percentage of total volume per service.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>
          {serviceNames.map((service) => {
            const { Volume: volume } = attribution[service];
            const { volume: totalVolume } = recommendation;
            const percentage = (volume / totalVolume) * 100;
            const roundedPercentage = lodashRound(percentage, 2);

            return (
              <div key={service} data-testid={`${service}-percentage`} className={styles.columnValues}>
                {roundedPercentage === 0 && percentage > 0 ? '<1' : roundedPercentage}%
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Savings
            <Tooltip placement={'top'} content={'The projected savings over 15 days with the configured drop rage.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>

          {serviceNames.map((serviceName) => {
            const { Volume: volume } = attribution[serviceName];
            const savings = volume * (recommendation.configured_drop_rate / 100);
            return (
              <div className={styles.columnValues} key={serviceName} data-testid={`${serviceName}-savings`}>
                <Bytes bytes={savings} decimals={1} />
              </div>
            );
          })}
        </div>
        <div className={styles.detailColumn}>
          <div>
            Volume
            <Tooltip placement={'top'} content={'The ingested volume over the last 15 days.'}>
              <Icon className={styles.infoIcon} name="info-circle" />
            </Tooltip>
          </div>

          {serviceNames.map((serviceName) => {
            const { Volume: volume } = attribution[serviceName];
            return (
              <div className={styles.columnValues} key={serviceName} data-testid={`${serviceName}-volume`}>
                <Bytes bytes={volume} decimals={1} />
              </div>
            );
          })}
        </div>
        {hasDropRateOverrides && (
          <div className={styles.detailColumn}>
            <div>
              Drop rate
              <Tooltip
                placement={'top'}
                content={'The per-service drop rate, which will override the default pattern drop rate if it is set.'}
              >
                <Icon className={styles.infoIcon} name="info-circle" />
              </Tooltip>
            </div>

            {serviceNames.map((serviceName) => {
              const segmentConfig = segments[serviceName];
              const displayDropRate =
                segmentConfig?.drop_rate == null ? (
                  <Tooltip
                    placement={'left'}
                    content={`The default drop rate for this pattern is ${configured_drop_rate}%.`}
                  >
                    <span className={styles.defaultValue}>{configured_drop_rate}%</span>
                  </Tooltip>
                ) : (
                  `${segmentConfig.drop_rate}%`
                );
              return (
                <div className={styles.columnValues} key={serviceName} data-testid={`${serviceName}-volume`}>
                  {displayDropRate}
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
