import { useCallback } from 'react';

import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';

import { FetchError, getBackendSrv } from '@grafana/runtime';

import { DEFAULT_GET_KEY, getWithMappedItems } from '@/api';
import {
  Exemption,
  ExemptionsQueryResponse,
  getPatternRecommendationKey,
  Metrics,
  PatternRecommendation,
} from '@/api/types';
import { errorAlert, successAlert } from '@/utils/alert';
import { paths, QUERY_KEYS } from '@/utils/constants';
import { downloadJson } from '@/utils/download';

export const useRecommendations = () =>
  useSuspenseQuery({
    queryFn: () => getWithMappedItems(paths.recommendations, getPatternRecommendationKey, { format: 'json' }),
    queryKey: [QUERY_KEYS.recommendations],
  });

export const useUpdateRecommendationsMutation = () => {
  const queryClient = useQueryClient();

  return useMutation<unknown, FetchError, PatternRecommendation[]>({
    mutationFn: async (recommendations) => {
      await getBackendSrv().post(paths.recommendations, recommendations, {
        headers: { 'Content-Type': 'application/json' },
        showErrorAlert: false,
      });
    },
    onError: async (error: FetchError) => {
      errorAlert(error.data.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.recommendations] });
      successAlert('Drop rates saved');
    },
  });
};

export const useMetrics = () =>
  useSuspenseQuery({
    queryFn: () => getBackendSrv().get<Metrics>(paths.metrics),
    queryKey: [QUERY_KEYS.metrics],
  });

// TODO need to pass in a header
export const useSummary = () =>
  useSuspenseQuery({
    queryFn: () =>
      getWithMappedItems(
        paths.summary,
        DEFAULT_GET_KEY // It is not clear if these this keyed map for this, but I am restoring the original functionality before I added `getMap` as a parameter
      ),
    queryKey: [QUERY_KEYS.summary],
  });

export const useExemptions = () =>
  useSuspenseQuery({
    queryFn: () => getBackendSrv().get<ExemptionsQueryResponse>(paths.exemptions),
    queryKey: [QUERY_KEYS.exemptions],
  });

export const useCreateExemptionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (exemption: Partial<Exemption>) => {
      await getBackendSrv().post(paths.exemptions, exemption, { showErrorAlert: false, showSuccessAlert: false });
    },
    onError: async (error: FetchError) => {
      errorAlert(error.data.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.exemptions],
      });
      successAlert('Successfully created exemption.');
    },
  });
};

export const useUpdateExemptionMutation = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id, reason, stream_selector }: Partial<Exemption>) => {
      if (!id) {
        return Promise.reject('Id missing from exemption');
      }

      return await getBackendSrv().put(
        `${paths.exemptions}/${id}`,
        { reason, stream_selector },
        { showErrorAlert: false, showSuccessAlert: false }
      );
    },

    onError: async (error: FetchError) => {
      errorAlert(error.data.message);
    },
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEYS.exemptions],
      });
      successAlert('Successfully updated exemption');
    },
  });
};

export const useDeleteExemptionMutation = (bypassAlert = false) => {
  return useMutation({
    mutationFn: (id: string) =>
      getBackendSrv().delete(`${paths.exemptions}/${id}`, null, {
        showErrorAlert: false,
        showSuccessAlert: false,
      }),
    onError: (err: FetchError) => {
      if (!bypassAlert) {
        errorAlert(err.data.message);
      }
      return;
    },
  });
};

export const useDownloadExemption = () => {
  const { data: exemptions } = useExemptions();

  return useCallback(() => {
    if (!exemptions) {
      return;
    }

    downloadJson(exemptions, 'exemptions.json');
  }, [exemptions]);
};
