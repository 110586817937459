import React, { useMemo } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Counter, Icon, Tooltip, useStyles2 } from '@grafana/ui';

import { PatternRecommendation } from '@/api/types';

const getStyles = (theme: GrafanaTheme2) => ({
  currentRate: css({
    width: '3em',
  }),
  currentRateContainer: css({
    display: 'flex',
    marginLeft: theme.spacing(2),
  }),
  lockIcon: css({
    left: theme.spacing(-1.5),
    marginRight: theme.spacing(-2),
    position: 'relative',
    top: theme.spacing(0.25),
  }),
  noTextSelect: css({ userSelect: 'none' }),
});

export const CurrentRate = ({ recommendation }: { recommendation: PatternRecommendation }) => {
  const { configured_drop_rate } = recommendation;
  const styles = useStyles2(getStyles);

  const overridePill = useMemo(() => {
    if (!recommendation.segments) {
      return null;
    }

    const overrideCount = Object.keys(recommendation.segments).length;
    if (!overrideCount) {
      return null;
    }

    const tooltip =
      overrideCount === 1 ? `${overrideCount} per-service drop rate` : `${overrideCount} per-service drop rates`;

    return (
      <Tooltip content={tooltip}>
        <span className={styles.noTextSelect}>
          <Counter value={overrideCount} />
        </span>
      </Tooltip>
    );
  }, [recommendation.segments, styles]);

  return (
    <div className={styles.currentRateContainer}>
      {recommendation.locked && <Icon data-testid="current-rate-icon" className={styles.lockIcon} name="lock" />}
      <div className={styles.currentRate} data-testid="current-rate-text">
        {configured_drop_rate}%
      </div>
      {overridePill}
    </div>
  );
};
