import { useLocation } from 'react-router-dom';

import { PageType } from '@/types';
import { paths } from '@/utils/constants';
import { pageTypeFromRoute } from '@/utils/methods';

export const useCurrentPage = (): PageType => {
  const pathname = useLocation().pathname;

  return pageTypeFromRoute(pathname.replace(paths.appRoot + '/', ''));
};
