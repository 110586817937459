import React, { Suspense, useMemo } from 'react';

import { css } from '@emotion/css';
import Skeleton from 'react-loading-skeleton';

import { GrafanaTheme2 } from '@grafana/data';
import { Column, InteractiveTable, Tooltip, useStyles2, useTheme2 } from '@grafana/ui';

import { ExemptionActionButtons } from '../ExemptionActionButtons';
import { ExemptionSummary } from '../ExemptionSummary';
import { Exemption } from '@/api/types';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    noRows: css({
      color: theme.colors.text.secondary,
      fontStyle: 'italic',
      margin: `${theme.spacing(2)} auto 0`,
    }),
    streamSelector: css({
      width: theme.spacing(30),
    }),
    streamSelectorEllipsis: css({
      display: 'inline-block',
      maxWidth: '100%',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    }),
  };
};

type Styles = ReturnType<typeof getStyles>;

const getColumns = (styles: Styles, canUserEditExemption: boolean): Array<Column<Exemption>> => {
  return [
    {
      cell: ({ row: { original } }) => (
        <Tooltip content={original.stream_selector}>
          <div className={styles.streamSelector}>
            <span className={styles.streamSelectorEllipsis}>{original.stream_selector}</span>
          </div>
        </Tooltip>
      ),
      disableGrow: true,
      header: 'Stream selector',
      id: 'stream-selector',
    },
    {
      header: 'Reason',
      id: 'reason',
    },
    {
      cell: ({ row }) => {
        // TODO complete the RBAC logic
        return canUserEditExemption && <ExemptionActionButtons exemption={row.original} />;
      },
      disableGrow: true,
      header: 'Actions',
      id: 'actions',
    },
  ];
};

interface Props {
  exemptions: Exemption[];
}

const Component = ({ exemptions }: Props) => {
  const styles = useStyles2(getStyles);

  const columns = useMemo(() => getColumns(styles, true), [styles]);

  const tableData = useMemo(() => {
    if (!exemptions || exemptions.length === 0) {
      return [];
    }

    return exemptions;
  }, [exemptions]);

  const renderSubComponent = (exemption: Exemption) => {
    return <ExemptionSummary exemption={exemption} />;
  };

  return (
    <>
      <InteractiveTable
        pageSize={5}
        renderExpandedRow={renderSubComponent}
        columns={columns}
        data={tableData}
        getRowId={(exemption: Exemption) => exemption.id}
      />
      {!tableData.length && (
        <div className={styles.noRows} data-testid="no-data-message">
          No exemptions available
        </div>
      )}
    </>
  );
};

export const StreamSelectorTable = ({ exemptions }: Props) => {
  const theme = useTheme2();
  return (
    <Suspense fallback={<Skeleton count={5} height={theme.spacing(4)} />}>
      <Component exemptions={exemptions} />
    </Suspense>
  );
};
