import { getValueFormat } from '@grafana/data';

/**
 * TODO explore how getValueFormat is being used in other places
 * and refactor if it gets simpler
 */
const bytesFormatter = getValueFormat('bytes');

export function formatBytes(bytes: number, decimals?: number) {
  const { suffix, text } = bytesFormatter(bytes, decimals);
  return `${text}${suffix}`;
}

const bytesFormatterBase10 = getValueFormat('decbytes');

export function formatBytesBase10(bytes: number, decimals?: number) {
  const { suffix, text } = bytesFormatterBase10(bytes, decimals);
  return `${text}${suffix}`;
}

const localeFormatter = getValueFormat('locale');

export function formatNumber(value: number, decimals?: number) {
  const { text } = localeFormatter(value, decimals);
  return text;
}

export const getValueAndUnit = (bytes = 0, decimals = 0) => {
  if (bytes === 1) {
    return `${bytes} byte`;
  }
  if (Math.abs(bytes) < 1024) {
    return `${bytes} bytes`;
  }

  return formatBytes(bytes, decimals);
};
