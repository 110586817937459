import React, { useState } from 'react';

import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { Button, ConfirmModal, useStyles2 } from '@grafana/ui';

import { AddEditExemption } from '../AddEditExemption';
import { Exemption } from '@/api/types';
import { useDeleteExemptionMutation, useExemptions } from '@/hooks/api-hooks';
import { QUERY_KEYS } from '@/utils/constants';
import { noop } from '@/utils/methods';
import { queryClient } from '@/utils/state';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'flex',
    gap: theme.spacing(1),
  }),
});

interface Props {
  exemption: Exemption;
}

export const ExemptionActionButtons = ({ exemption }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const { isPending: exemptionDeleting, mutateAsync: deleteExemptionAsync } = useDeleteExemptionMutation();
  const { isFetching: exemptionFetching } = useExemptions();
  const styles = useStyles2(getStyles);

  const isDeleting = exemptionDeleting || exemptionFetching;

  return (
    <div className={styles.container}>
      <AddEditExemption exemption={exemption} />
      <Button
        aria-label="remove-exemption"
        size="sm"
        variant="secondary"
        icon="trash-alt"
        onClick={() => setIsOpen(true)}
      />
      <ConfirmModal
        isOpen={isOpen}
        title="Delete Exemption?"
        body={<div>Exemption will be removed</div>}
        confirmText={isDeleting ? 'Deleting...' : 'Delete'}
        confirmButtonVariant={isDeleting ? 'secondary' : 'destructive'}
        onConfirm={
          isDeleting
            ? noop
            : async () => {
                await deleteExemptionAsync(exemption.id);
                await queryClient.invalidateQueries({ queryKey: [QUERY_KEYS.exemptions] });
                await setIsOpen(false);
              }
        }
        onDismiss={
          isDeleting
            ? noop
            : () => {
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};
