import { Configuration } from '../Configuration';
import { Recommendations } from '@/pages/Recommendations';
import { PageDefinition, PageType } from '@/types';

export const pages = new Map<PageType, PageDefinition>([
  [
    'patterns',
    {
      component: Recommendations,
      id: 'patterns',
      text: 'Patterns',
    },
  ],
  [
    'configuration',
    {
      component: Configuration,
      id: 'configuration',
      text: 'Configuration',
    },
  ],
]);
