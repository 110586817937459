import React, { useState } from 'react';

import { css, cx } from '@emotion/css';
import { debounce as lodashDebounce } from 'lodash';

import { GrafanaTheme2 } from '@grafana/data';
import { Field, Icon, IconButton, Input, useStyles2 } from '@grafana/ui';

import { useFilter } from '@/hooks/context-hooks';

const getStyles = (theme: GrafanaTheme2) => ({
  filterField: css({
    marginBottom: 0,
  }),
});

export const FilterField = ({ className, placeholder }: { className?: string; placeholder?: string }) => {
  const styles = useStyles2(getStyles);
  const { setFilter } = useFilter();
  const [inputDisplay, setInputDisplay] = useState('');

  // Debounce to wait until 0.3s until users stop typing before updating filter
  const debouncedOnChange = lodashDebounce((newFilter) => {
    setFilter(newFilter);
  }, 300);

  const onChange = (value: string) => {
    setInputDisplay(value);
    debouncedOnChange(value);
  };

  return (
    <Field className={cx(styles.filterField, className)} label="Filter">
      <>
        <Input
          data-testid="pattern-filter"
          prefix={<Icon name="search" />}
          placeholder={placeholder || 'Pattern'}
          value={inputDisplay}
          suffix={inputDisplay && <IconButton aria-label="clear-input" name="times" onClick={() => onChange('')} />}
          onChange={(v) => onChange(v.currentTarget.value)}
        />
      </>
    </Field>
  );
};
