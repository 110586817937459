import React, { useState } from 'react';

import { some as lodashSome } from 'lodash';

import { Button, ConfirmModal } from '@grafana/ui';

import { useDeleteExemptionMutation, useExemptions } from '@/hooks/api-hooks';
import { warningAlert } from '@/utils/alert';
import { QUERY_KEYS } from '@/utils/constants';
import { noop } from '@/utils/methods';
import { queryClient } from '@/utils/state';

export const RemoveAllExemptions = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { data: exemptions, isFetching: exemptionFetching } = useExemptions();
  const { isPending: exemptionDeleting, mutateAsync: deleteExemptionAsync } = useDeleteExemptionMutation(true);

  const busy = exemptionDeleting || exemptionFetching;

  const onExemptionRemove = async () => {
    const exemptionsToDelete = exemptions.result.map(({ id }) => id) || [];

    const results = await Promise.allSettled(exemptionsToDelete.map((id) => deleteExemptionAsync(id)));
    const anyFailed = lodashSome(results, (result) => result.status === 'rejected');

    if (anyFailed) {
      warningAlert('Some exemptions failed to delete. Try again or contact support.', 'Deletion Failed');
    }

    await queryClient.invalidateQueries({
      queryKey: [QUERY_KEYS.exemptions],
    });
  };

  return (
    <div>
      <Button variant={'destructive'} disabled={!exemptions.result.length} onClick={() => setIsOpen(true)}>
        Remove all exemptions
      </Button>
      <ConfirmModal
        isOpen={isOpen}
        title="Delete Exemptions?"
        body={
          <>
            {exemptions.result.length} exemption{exemptions.result.length === 1 ? '' : 's'} will be removed
          </>
        }
        confirmText={busy ? 'Deleting...' : 'Delete'}
        confirmButtonVariant="destructive"
        onConfirm={
          busy
            ? noop
            : async () => {
                await onExemptionRemove();
                setIsOpen(false);
              }
        }
        onDismiss={
          busy
            ? noop
            : () => {
                setIsOpen(false);
              }
        }
      />
    </div>
  );
};
