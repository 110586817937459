export type PatternRecommendation = {
  attribution: Record<string, Attribution>;
  configured_drop_rate: number;
  ingested_lines: number;
  locked: boolean;
  pattern: string;
  queried_lines: number;
  recommended_drop_rate: number;
  segments?: Record<string, SegmentConfig>;
  volume: number;
};

type Attribution = {
  Count: number;
  Volume: number;
};

type SegmentConfig = {
  drop_rate?: number;
};

export function getPatternRecommendationKey(recommendation: PatternRecommendation) {
  return recommendation.pattern;
}

export type Summary = {
  dropped_volume: number;
  kept_volume: number;
  total_ingested_volume: number;
  unqueried_volume: number;
};

export type Metrics = {
  dropped_volume: number;
  ingested_volume: number;
};

export type ListResponseWithHeaders<Key, T> = {
  duplicateItems: T[];
  items: T[];
  mappedItems: Map<Key, T>;
};

export type Exemption = {
  created_at: string;
  created_by: string;
  id: string;
  managed_by?: string;
  reason?: string;
  stream_selector: string;
  updated_at: string;
};

export type ExemptionsQueryResponse = {
  result: Exemption[];
};
